.video {
  &-film {
    position: absolute;
    background: rgba($black, 0.3) url("/images/video-film.png");
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
