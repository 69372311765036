:root {
  --header-height: 0px;
}

$is-header-over-banner: false;

.fmd-header {
  position: if($is-header-over-banner, absolute, relative);
  top: 0;
  left: 0;
  right: 0;
  z-index: $zindex-sticky;
  margin-top: 0;
  transition: $transition-base;
  background: $secondary;

  &-is-hidden {
    margin-top: calc(var(--header-height) * -1);
  }

  &-is-fixed {
    //background: $body-bg;
  }
}

body {
  padding-top: if($is-header-over-banner, 0px, var(--header-height));
}
