:root {
  --button-width: 100%;
  --slide-transition: none;
}

.index {
  .pointer-events-none {
    pointer-events: none;
  }
}
