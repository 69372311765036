.card {

  &-small {
    border-radius: calc($border-radius * .5);
  }

  &-medium {
    border-radius: $border-radius;

    @include media-breakpoint-up(md) {
      --#{$prefix}card-spacer-x: 1.5rem;
      --#{$prefix}card-spacer-y: 1.5rem;
    }
  }

  &-large {

    border-radius: calc($border-radius * 2) 0 calc($border-radius * 2) calc($border-radius * 2);
    background: $white;

    .img-cover {
      border-radius: calc($border-radius * 2) 0 calc($border-radius * 2) calc($border-radius * 2);
    }

    @include media-breakpoint-up(md) {
      --#{$prefix}card-spacer-x: 2rem;
      --#{$prefix}card-spacer-y: 2rem;
    }
  }
}
