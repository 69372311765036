.modal {
  --#{$prefix}btn-close-opacity: 1;
  @include media-breakpoint-up(md) {
    --#{$prefix}modal-header-padding: 1.5rem;
    --#{$prefix}modal-padding: 0 var(--#{$prefix}modal-header-padding) var(--#{$prefix}modal-header-padding);
  }

  &-title {
    font-size: $font-size-base;
    color: $dark;
    text-transform: unset;

    @include media-breakpoint-up(md) {
      font-size: $h3-font-size;
    }
  }

  &-table {
    border: 1px solid $dark;
    border-radius: calc($border-radius * 1.5) 0 calc($border-radius * 1.5) calc($border-radius * 1.5);
    color: $dark;
    background: $light;

    .row {
      [class*="col"] {
        border-bottom: 1px solid $primary;
        border-right: 1px solid $primary;

        &:nth-child(3n + 3),&:nth-child(3) {
          border-right: 0;
        }

        &:nth-last-child(-n + 3) {
          border-bottom: 0;
        }

        &:nth-child(-n + 3) {
          color: $black;
          font-weight: $font-weight-medium;
        }
      }

      .col-3 {
        text-align: center;
      }
    }

    &-field {
      display: block;
      padding: .625rem .75rem;
      font-size: $font-size-xs;

      @include media-breakpoint-up(md) {
        font-size: $font-size-base;
      }
    }
  }
}