:root {
  --swiper-theme-color: #{$secondary};
  --swiper-pagination-bullet-horizontal-gap: .5rem;
  --swiper-pagination-bullet-height: 2.5rem;
  --swiper-pagination-bullet-width: 2.5rem;
  --swiper-pagination-color: none;
  --swiper-pagination-bullet-inactive-color: none;
  --swiper-pagination-bullet-inactive-opacity: 1;
}

.swiper {

  &-button-disabled {
    color: $gray-600;
  }

  &-button-prev,
  &-button-next {
    position: unset;
    color: $primary;
    margin-top: 0;

    &,svg {
      width: 2rem;
      height: 2rem;
    }

    &:after {
      content: unset;
    }
  }

  &-pagination-bullet {
    svg {
      color: transparent;
    }

    &-active {
      svg {
        color: $primary;
      }
    }
  }

  &-slide {

    height: auto;

    img {
      aspect-ratio: auto;
    }
  }

  &-container {
    position: relative;
  }

  &-wrapper {

    align-items: stretch;

    &:focus {
      outline: none;
    }
  }

  &.js-slider {

    + .swiper-action .swiper-pagination-bullets {

      display: flex;
      bottom: 0;

      .swiper-pagination-bullet {
        display: inline-flex;
        --swiper-pagination-bullet-height: 1.5rem;
        --swiper-pagination-bullet-width: 1.25rem;
      }
    }
  }
}
