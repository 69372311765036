@import "../frontend/warnings";

a {

  &:not(.skip-warning) {

    &:empty:not([aria-label], [title], [class*="adopt-"]) {
      @include accessibility-warning;
    }

    &:has(img):not([aria-label]):not([aria-labelledby]) {
      @include accessibility-warning;
    }
  }
}

img {

  &:not(.skip-warning) {

    &:not([alt], [loading='lazy'], .fancybox-image) {
      @include accessibility-warning;
    }
  }
}
