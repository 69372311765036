.form {
  &-check-label {
    font-size: $font-size-xs;
  }

  &-check-input {
    &:not(:checked) {
      + .form-check-label {
        color: $white;
      }
    }
  }
}

.invalid-feedback {
  position: absolute;
  left: 0;
  top: 100%;
}