%heading {
  text-transform: $headings-text-transform;

  &:last-child {
    margin-bottom: 0;
  }
}

.text-truncate {
  @for $i from 1 through 6 {
    &-#{$i} {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: $i; /* number of lines to limit */
      line-clamp: $i;
    }
  }
}

h2 {
  margin-bottom: $block-spacer-small;
  font-size: $h3-font-size;
  text-align: center;

  @include media-breakpoint-up(md) {
    margin-bottom: $block-spacer;
    font-size: $h1-font-size;
  }
}

p {
  font-weight: $font-weight-medium;

  @include media-breakpoint-up(md) {
    font-size: $ds-h3-font-size;
  }
}

.fs-md-3 {
  @include media-breakpoint-up(md) {
    font-weight: $font-weight-medium;
  }
}