.transition-base {
  transition: $transition-base;
}

.swiper-product-animation {

  &::-webkit-scrollbar {
    display: none;
  }

  .collapsing {
    opacity: 0;
    @include media-breakpoint-up(sm) {
      height: 0 !important;
    }
  }

  .collapse {
    transition: $transition-base;
  }

  .js-open-collapse {
    width: 100%;
    @include media-breakpoint-up(sm) {
      width: var(--button-width);
      min-width: var(--button-width);
    }
  }

  .button {
    &-title {
      opacity: 1;
      transition: $transition-base;
    }

    &-img {
      padding-inline: .75rem;
      transition: $transition-base;
    }
  }

  .swiper {

    &-wrapper {
      transition: $transition-base;
      transition-duration: .2s !important;
    }

    &-slide {

      &::-webkit-scrollbar {
        display: none;
      }

      transition: var(--slide-transition);
      .position-relative {
        @include media-breakpoint-up(sm) {
          width: var(--button-width);
        }
        transition: $transition-base;
        %heading {
          font-size: $font-size-base;
          @include media-breakpoint-up(sm) {
            font-size: $font-size-sm
          }
          @include media-breakpoint-up(xl){
            font-size: $font-size-xl
          }
        }
      }

      &-hover {

        transform: scale(1.1);

        .position-relative {
          width: 100%;
        }
      }

      .ic-close {
        opacity: 0;
        transition: $transition-base;

        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-50%, -50%);
      }

      .pseudo-product {
        position: relative;

        &:before {
          position: absolute;
          background: red;
          z-index: -1;
          content: "";
          top: 0;
          left: 0;
          width: 100%;
          height: 84%;
          transition: $transition-base;
          border-radius: $border-radius-md 0 $border-radius-md $border-radius-md;
        }
      }

      @mixin products-colors($answer-text-color,$bg-color,$text-color,$pseudo-color) {
        %heading {
          color: $text-color
        }
        color: $text-color;
        .question-products {
          color: $answer-text-color;
          background: $bg-color;
        }

        .pseudo-product:before {
          background: $pseudo-color;
        }
      }
      &:first-of-type {
        @include products-colors($info, $secondary,$secondary,$info)
      }

      &:nth-of-type(2) {
        @include products-colors($primary, $secondary,$primary,$warning)
      }

      &:nth-of-type(3) {
        @include products-colors($primary, $secondary,$secondary,$santa-fe)
      }

      &:nth-of-type(4) {
        @include products-colors($secondary, $dark,$dark,$success)
      }

      &:last-of-type {
        margin-right: 0 !important;
        @include products-colors($secondary, $dark,$dark, $gumbo)
      }



      &.opend-collapse {
        width: 100% !important;

        .ic-close {
          opacity: 1;
          @include media-breakpoint-up(sm) {
            top: 8%;
          }
        }

        .button {
          &-title {
            opacity: 0;
            line-height: 0;
          }

          &-img {
            @include media-breakpoint-down(sm) {
              padding-inline: 5rem;
            }
          }
        }

        .pseudo-product::before {
          margin-top: 32%;
          height: 90%;
          @include media-breakpoint-up(sm) {
            border-radius: $border-radius-lg 0 $border-radius-lg $border-radius-lg;
            margin-top: 0;
            height: 100%;
            width: 91%;
            margin-left: 9%;
          }
        }
      }
    }
  }
}
