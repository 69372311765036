section {
  position: relative;
}

textarea {
  resize: none;
}

p:last-child {
  margin-bottom: 0;
}

a {
  outline: none !important;
  transition: $transition-base;
}

.text-unset {
  text-transform: unset;
}