// colors
$ds-gray-100: #f8f9fa;
$ds-gray-200: #e9ecef;
$ds-gray-300: #dee2e6;
$ds-gray-400: #ced4da;
$ds-gray-500: #9F9F9F;
$ds-gray-600: #A6A29F;
$ds-gray-700: #495057;
$ds-gray-800: #343a40;
$ds-gray-900: #212529;
$ds-blue: #0d6efd;
$ds-indigo: #6610f2;
$ds-purple: #6f42c1;
$ds-pink: #d63384;
$ds-red: #dc3545;
$ds-orange: #fd7e14;
$ds-yellow: #ffc107;
$ds-green: #198754;
$ds-teal: #20c997;
$ds-cyan: #0dcaf0;
$ds-black: #000000;
$ds-white: #ffffff;

// theme-colors
$mule-fawn: #8C412A; //primary
$santa-fe: #B76E50; //primary-subtle
$dawn-pink: #EFE8DD; // secondary
$green-smoke: #A1A367; //success
$bismark: #4D738A; //info
$gumbo: #7AA6AD; //info-subtle
$rob-roy: #E7BA6E; //warning
$alizarin-crimson: #d72638; //danger
$mine-shaft: #3F3E3E; //dark
$concrete: #F2F2F2; //light

$ds-primary: $mule-fawn;
$ds-secondary: $dawn-pink;
$ds-success: $green-smoke;
$ds-info: $bismark;
$ds-warning: $rob-roy;
$ds-danger: $alizarin-crimson;
$ds-light: $concrete;
$ds-dark: $mine-shaft;
