.accordion {
  &-button {
    font-size: $h4-font-size;
    font-weight: $font-weight-bold;
    line-height: $ds-line-height-sm;
    @include media-breakpoint-up(md) {
      font-size: $h3-font-size;
      font-weight: $font-weight-medium;
    }
  }

  &-body {

  }
}